import React from "react"
import { css } from "@emotion/core"
import Layout from "../../components/Layout"
import ProjectHero from "../../components/heros/ProjectHero"
import DoubleColumn from "../../components/projects/DoubleColumn"
import BannerDoubleColumn from "../../components/projects/BannerDoubleColumn"
import Banner from "../../components/projects/Banner"
import Download from "../../components/projects/Download"
import Logo from "../../components/projects/Logo"
import NextProject from "../../components/projects/NextProject"

import HeroImage from "../../images/projects/pulzus/hero.jpg"
import ProjectMobileImage from "../../images/projects/pulzus/project_mobile.png"
import BannerImage from "../../images/projects/pulzus/banner.jpg"
import ScreenshotImage from "../../images/projects/pulzus/screenshot.png"
import ColumnImage from "../../images/projects/pulzus/column.jpg"
import InstallImage from "../../images/projects/pulzus/install.png"
import LogoImage from "../../images/projects/pulzus/logo.png"

import NextImage from "../../images/projects/info/hallid.ai.jpg"

const Footer = () => (
  <Download
    css={css`
      margin-bottom: 100px;

      @media (max-width: 650px) {
        margin-bottom: 50px;
      }
    `}
    androidLink="https://play.google.com/store/apps/details?id=com.szph.Pulzus"
    iosLink="https://apps.apple.com/hu/app/pulzus/id1236430068"
  />
)

const Next = () => (
  <NextProject
    image={NextImage}
    title="Fridai"
    description="The virtual gaming assistant"
    to="fridai"
  />
)

const Hero = () => (
  <ProjectHero
    title="Pulzus"
    subtitle="Change the world with your opinion. Tell us what you think!"
  />
)

const PulzusProject = () => (
  <Layout withHero heroContent={Hero} heroImage={HeroImage} infoContent={Next}>
    <div
      css={theme => css`
        text-align: center;
        min-height: calc(
          100vh - ${theme.constants.infoFullHeight / 2}px -
            ${theme.constants.footerHeight}px -
            ${theme.constants.headerHeight}px
        );
        justify-content: center;
        display: flex;
        flex-direction: column;

        @media (max-width: 1000px) {
          position: relative;
          top: -100px;
        }

        @media (max-width: 650px) {
          position: relative;
          top: -130px;
        }
      `}
    >
      <div
        css={theme => css`
          margin: ${theme.constants.pageMargin};

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: ${theme.constants.smallpageMargin};
          }
        `}
      >
        <DoubleColumn
          first
          title="New digital polling solution on the market"
          description="A new, innovative mobile application to measure public opinion"
          image={ProjectMobileImage}
          flip
          border={0}
          top={0}
          imageOverride={css`
            @media (max-width: 650px) {
              height: 93vw;
            }

            @media (min-width: 1920px) {
              height: 700px;
              padding: 0;
            }
          `}
        />
      </div>
      <Banner image={BannerImage} />
      <div
        css={theme => css`
          margin: ${theme.constants.pageMargin};

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: ${theme.constants.smallpageMargin};
          }
          margin-top: 100px;
        `}
      >
        <div
          css={theme => css`
            font-size: ${theme.constants.smallFontSize}px;
            font-weight: ${theme.constants.extraLight};
            margin-bottom: 100px;
          `}
        >
          <p
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.demi};
              margin-bottom: 30px;
            `}
          >
            Your opinion matters!
          </p>
          <p>
            Voting is not only your inalienable right but even an opportunity.
            It’s a chance for us all to get to know our world better and even
            change it for the better.
          </p>
        </div>
        <DoubleColumn
          title="Voting can be both fun and educational at the same time"
          description="The app will send you questionnaires on a regular basis, about everyday life, traditions, public issues. After voting you can see what other users think."
          image={ScreenshotImage}
          border={0}
          top={150}
          flip
          imageOverride={css`
            @media (max-width: 650px) {
              height: 96vw;
            }

            @media (min-width: 1920px) {
              height: 700px;
              padding: 0;
            }
          `}
        />
        <DoubleColumn
          title="A poll where your vote matters"
          description="The application motivates and encourages you to vote and answer the questions. After every vote, you receive Pulzus-points that can be turned into valuable gifts later."
          image={ColumnImage}
          top={10}
          css={css`
            margin-top: 50px;
            margin-bottom: 100px;
          `}
          imageOverride={css`
            @media (max-width: 650px) {
              height: 65vw;
            }

            @media (min-width: 1920px) {
              height: 500px;
            }
          `}
        />
      </div>
      <BannerDoubleColumn
        title="Innovative digital solutions with experienced analysts."
        description="In Pulzus, questions and answers are formulated according to the scientific methodology used in public opinion polls. The research is valued, weighted and analyzed by the most experienced researchers in Hungary."
        image={InstallImage}
        top={0}
        border={50}
        flip
        color="#2E3FA0"
        color2="#10FD78"
        footer={Footer}
        column1={4}
        column2={6}
        css={css`
          padding-top: 100px;
          background-size: 100%;
          background-position-y: -50px;
          margin-bottom: 100px;

          @media (max-width: 650px) {
            padding-top: 50px;
            margin-bottom: 0;
            background-size: 100% 90%;
          }
        `}
        imageOverride={css`
          @media (min-width: 1920px) {
            height: 700px;
            padding: 0;
          }
        `}
      />
      <Logo
        src={LogoImage}
        css={css`
          height: 140px;
          margin: 0 auto;
          margin-bottom: 150px;

          @media (max-width: 650px) {
            height: 25vw;
            margin-bottom: 0;
            margin-top: 50px;
          }
        `}
      />
    </div>
  </Layout>
)

export default PulzusProject
